import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Content from "../../components/Content"
import Testimonial from "../../components/Testimonial";
import GenericLottie from "../../components/GenericLotti";
import Slider from "react-slick";
import right from '../../assets/misc/slideArrowRight.svg';
import left from '../../assets/misc/slideArrowLeft.svg';
import blush from '../../assets/lotties/blush.json';
import {HEADLINE, TITLE, testimonials} from "./constants";

export default function Testimonials() {

    const ArrowLeft = (props) => (
        <img src={left}
             {...props}
             className={'!hidden lg:!block slick-prev '}
             alt={""}
        />
    );

    const ArrowRight = (props) => (
        <img
            src={right}
            {...props}
            className={'!hidden lg:!block slick-next'}
            alt={""}
        />
    );

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: <ArrowLeft/>,
        nextArrow: <ArrowRight/>,
        customPaging: function (i) {
            return <div className={`text-transparent`}> {i} </div>;
        },
        dotsClass: "slick-dots slick-thumb",
    }

    return (
        <div className={` lg:mt-20 relative`}>
            <div className={`flex flex-col  w-fit mx-auto lg:flex-row `}>
                <div className={`max-w-[400px] lg:absolute  lg:-top-56 lg:right-20 xl:right-32`}>
                    <GenericLottie lottieAsset={blush}/>
                </div>
                <Content
                    headline={{
                        content: HEADLINE,
                        class: `text-gini-green text-center font-Pauline text-70`
                    }}
                    title={{
                        content: TITLE,
                        class: `text-gini-orange text-center font-Poppins font-bold text-40`
                    }}
                />

            </div>
            <Slider  {...settings} >
                {
                    testimonials.map(slide => {
                        return (
                            <Testimonial logo={slide.logo} paragraph={slide.paragraph} name={slide.name}
                                         title={slide.title}/>
                        )
                    })
                }
            </Slider>
        </div>
    )
}