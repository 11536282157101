import Slider from "react-slick";

export default function ProductCarouselMobile({product}) {

    const settings = {
        centerMode: true,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 375,
                settings: {
                    centerPadding: '70px',
                }
            },
            {
                breakpoint: 425,
                settings: {
                    centerPadding: '90px',
                }
            },
            {
                breakpoint: 769,
                settings: {
                    centerPadding: '210px',
                }
            },
        ]
    }


    return (
        <div className={`my-10 ${product.name === "Um" && "!-mt-32"}`}>
            <Slider  {...settings}>
                {
                    product.after.map(picture => {
                        return (
                            <div
                                className={`!min-h-[500px] !flex`}>
                                <div
                                    className={`${product.name === "Um" ? "-mb-32  max-w-[400px]" : "max-w-[150px]"}  mx-auto self-center `}>
                                    <img src={picture} alt={"product"}/>
                                </div>
                            </div>
                        )
                    })
                }
            </Slider>

            <div className={`w-2/3 mx-auto`}>
                <img src={product.logo} alt={"logo"}/>
            </div>
            <p className={`w-2/3 mx-auto text-white text-left  font-Poppins font-medium leading-[22px] mt-6`}>
                {product.paragraph}
            </p>
        </div>
    )
}