import kickOffLottie from "../../assets/lotties/kickOff.json";
import researchLottie from "../../assets/lotties/research.json";
import uxDesign from "../../assets/lotties/uxDesign.json";
import uiDesign from "../../assets/lotties/uiDesign.json";
import working from "../../assets/lotties/working.json";


export const kickOff = {
    title: {
        content: `Kick-off`,
        class: `content-title text-gini-green`
    },
    paragraph: {
        content: `Every digital product design partnership starts with an introduction, deep-dive into the project brief, establishing a communication channel, and a mapped-out product design plan.`,
        class: `content-paragraph`
    },
    lotti: kickOffLottie,
    styles: {
        container: `content-div mt-96 lg:mt-80 lg:ml-80  xl:ml-[375px]`,
        content: `mx-auto max-w-[250px]  lg:min-w-[350px] lg:mt-24 lg:mr-0`,
        lotti: `mx-auto mt-5  max-w-[250px] lg:max-w-[350px]   lg:mt-0`
    }
}

export const research = {
    title: {
        content: `Research`,
        class: `content-title text-gini-green`
    },
    paragraph: {
        content: `User-first design starts with a research mission that enables a thorough understanding of the user needs and the company’s business objectives.`,
        class: `content-paragraph`
    },
    lotti: researchLottie,
    styles: {
        container: `content-div lg:flex-row-reverse mt-20  lg:mt-10  lg:ml-44  xl:mt-20 xl:ml-72 `,
        content: `mx-auto max-w-[250px] lg:min-w-[350px]  self-center`,
        lotti: `mx-auto  self-center lg:mr-24  max-w-[250px] lg:max-w-[350px] `
    }
}

export const ux = {
    title: {
        content: `UX design`,
        class: `content-title text-gini-green`
    },
    paragraph: {
        content: `Seamless user experience, Iterate and polish to creat the best product experience.`,
        class: `content-paragraph`
    },
    lotti: uxDesign,
    styles: {
        container: `content-div  mt-16  lg:mt-10 xl:mt-8 lg:ml-44  xl:ml-64`,
        content: `mx-auto max-w-[250px] lg:mt-16 lg:min-w-[350px] lg:mr-20`,
        lotti: 'mx-auto max-w-[250px]  lg:max-w-[250px]'
    }
}

export const ui = {
    title: {
        content: `UI design`,
        class: `content-title text-gini-green`
    },
    paragraph: {
        content: `Aesthetic, consistent and user-friendly design. We creat flawless design when each milestone is perfect.`,
        class: `content-paragraph`
    },
    lotti: uiDesign,
    styles: {
        container: `mx-auto flex flex-col lg:flex-row-reverse  w-fit  lg:ml-44  lg:mt-14 xl:ml-64`,
        content: `mx-auto max-w-[250px] lg:min-w-[350px]`,
        lotti: `mx-auto mt-5 lg:mt-0 max-w-[300px]  lg:mr-52 xl:mr-56`
    }
}

export const header = {
    headline: {
        content: 'The Design Process',
        class: `text-center text-40 text-gini-green my-20 font-Poppins font-bold`
    }
}

export const brilliant = {
    headline: {
        content: `Your Brilliant`,
        class: `text-gini-green text-40 lg:text-80 text-center font-Pauline lg:leading-[75px]`
    },
    title: {
        content: `Digital Product`,
        class: `text-center text-[20px] lg:text-40 font-Poppins font-bold text-gini-orange lg:leading-[75px]`
    }
}
export const notGoingAnywhere = {
    title: {
        content: `We are not going anywhere`,
        class: `content-title text-gini-green text-left leading-[40px]`
    },
    paragraph: {
        content: `After the project delivery, we continue collecting feedback and inspecting the product usage, to support its growth and evolution, and future needs.`,
        class: `content-paragraph mt-4`
    },
    button: {
        content: `Let's Talk`,
        class: ` button hidden mt-3 lg:mt-5 xl:mt-10 ml-12 lg:block `
    },
    lotti: working,
    styles: {
        container: `content-div mt-32 lg:ml-64 lg:mt-16 xl:mt-16 xl:ml-[350px]`,
        content: `content-container mt-10 mx-auto max-w-[250px] lg:min-w-[350px]`,
        lotti: `mx-auto max-w-[350px] lg:min-w-[500px]`
    }
}