import {scrollToBottom} from "./Scroll";

export default function Content({container, headline, title, paragraph, button, footerRef}) {

    return (
        <div className={container}>
            {
                headline &&
                <p className={headline.class}>
                    {headline.content}
                </p>
            }
            {
                title &&
                <p className={title.class}>
                    {title.content}
                </p>
            }
            {
                paragraph &&
                <p className={paragraph.class}>
                    {paragraph.content}
                </p>
            }
            {
                button &&
                <div className={`button-container`}>
                    <button onClick={() => scrollToBottom(footerRef)} className={button.class}>
                        {button.content}
                    </button>
                </div>
            }
        </div>
    )
}