import {useState, useEffect} from 'react';

const OPTION = "option";

const useForm = (callback, validate) => {

    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            callback();
        }
    }, [errors]);

    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        setErrors(validate(values));
        setIsSubmitting(true);
    };

    const handleChange = (event) => {
        event.persist();
        setValues(values => ({...values, [event.target.name]: event.target.value}));
    };

    const handleOptionChange = (item) => {
        setValues({
                ...values,
                [OPTION]: item
            }
        );
    }

    return {
        handleChange,
        handleSubmit,
        handleOptionChange,
        values,
        errors,
    }
};

export default useForm;