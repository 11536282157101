import {useEffect, useState} from "react";
import axios from "axios";
import useForm from "../hooks/useForm";
import validate from "./validate";
import Dropdown from "./Dropdown";
import Input from "./Input";
import ThankYou from "./ThankYou";
import Content from "./Content";
import Spinner from "./Spinner";
import {OPTIONS, TELL_US} from "../sections/Footer/constants";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker";
import ReactGA from "react-ga";

const EMAIL = "email";
const NAME = "name";
const COMPANY = "company";
const MOBILE = "mobile";
const TEXT = "text";

export default function Form() {

    const gaEventTracker = useAnalyticsEventTracker('Contact us - Landing Page');

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        handleOptionChange
    } = useForm(submit, validate);

    const [filled, setFilled] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);

    async function submit() {
        setLoading(true)
        console.log('No errors, submit callback called!');
        try {
            await axios.post('/.netlify/functions/serverless/', {values}).then(response => {
                setSuccess(true)
                setLoading(false)
                window.history.pushState({}, 'studio-thank-you', '/studio-thank-you');
                const page = '/studio-thank-you';
                ReactGA.set({ page });
                ReactGA.pageview(page)
                }
            )
        } catch (e) {
            console.log(e.message)
            setSuccess(false)
            setLoading(false)
        }
    }

    useEffect(() => {
        setFilled(checkFilled())
    }, [values])

    const checkFilled = () => {
        if (Object.keys(values).length < 6) {
            return false
        }
        let flag = true
        Object.keys(values).map(item => {
            if (values[item] === "") {
                flag = false
            }
        })
        return flag
    }

    return (
        <div>
            {
                success ?
                    <ThankYou/>
                    :
                    <form onSubmit={handleSubmit} className={`mt-10 lg:mt-40 p-10`}>
                        <Content
                            title={{
                                content: TELL_US,
                                class: `text-gini-blue  text-center text-[20px] mb-5  font-Poppins font-bold lg:text-left`
                            }}
                        />
                        <div
                            className={` flex flex-col lg:flex-row mx-auto lg:space-y-0 lg:space-x-10 `}>
                            <div className={`flex flex-col  lg:w-[270px]`}>
                                <Input
                                    name={NAME}
                                    value={values.name || ''}
                                    onChange={handleChange}
                                    error={errors.name}
                                />
                                <Input
                                    name={COMPANY}
                                    value={values.company || ''}
                                    onChange={handleChange}
                                    error={errors.company}
                                />
                                <Input
                                    name={EMAIL}
                                    value={values.email || ''}
                                    onChange={handleChange}
                                    error={errors.email}
                                />
                                <Input
                                    name={MOBILE}
                                    value={values.mobile || ''}
                                    onChange={handleChange}
                                    error={errors.mobile}
                                />
                            </div>

                            <div className={`flex flex-col lg:w-[270px]`}>
                                <Dropdown items={OPTIONS} setOption={handleOptionChange}/>
                                <div className={`h-[30px]`}>
                                    {errors.option && (
                                        <p className="text-gini-red pl-3 text-[12px]">{errors.option}</p>
                                    )}
                                </div>
                                <textarea
                                    name={TEXT}
                                    value={values.text || ''}
                                    onChange={handleChange}
                                    className={`resize-none h-[130px] text-gini-blue border-gini-blue border-[1px] rounded-3xl p-3 placeholder:text-gini-blue`}
                                    rows="4" cols="`30"
                                    placeholder={'Your message...'}>
                                </textarea>
                                <div className={`h-[30px] flex items-end mx-auto mb-1`}>
                                    {
                                        Object.keys(errors).length > 0 ?
                                            <p className={`text-gini-red text-[12px]`}>
                                                Something’s wrong. Please check the Fields
                                            </p>
                                            :
                                            (
                                                filled ?
                                                    <p className={`text-gini-orange text-[12px]`}>
                                                        Wow, we are so excited…
                                                    </p>
                                                    :
                                                    Object.keys(values).length !== 0 &&
                                                    <p className={`text-gini-orange text-[12px]`}>
                                                        Don’t forget to enter all fields!
                                                    </p>
                                            )
                                    }
                                </div>
                                <button
                                    type={"submit"}
                                    className={'mx-auto !w-full bg-gini-button-green text-base text-white font-Poppins font-bold rounded-3xl h-12 lg:w-48'}
                                >
                                    {
                                        loading ?
                                            <Spinner/>
                                            :
                                            <span> Submit </span>
                                    }
                                </button>
                            </div>
                        </div>
                    </form>
            }
        </div>
    )
}