import Content from "../../components/Content";
import GenericLottie from "../../components/GenericLotti";
import {HEADLINE, TITLE, PARAGRAPH, LETS_TALK} from "./constants";
import studio from "../../assets/lotties/studio.json";
import curvedArrowRight from "../../assets/misc/curvedArrowRight.svg";
import curvedArrowLeft from "../../assets/misc/curvedArrowLeft.svg";
import plus from "../../assets/elements/plus.svg";

export default function AboutUs({footerRef}) {
    return (
        <div className={`relative`}>
            <img src={plus} className={`absolute lg:left-10`} alt={""}/>
            <img src={plus} className={`hidden lg:block absolute  lg:right-44 bottom-0`} alt={""}/>
            <div
                className={`relative flex flex-col mt-20 lg:mt-0 -space-y-40 sm:-space-y-40  lg:space-y-0 lg:-space-x-64 lg:flex-row justify-center mx-auto w-fit   p-5 lg:p-10`}>
                <div
                    className={` bg-white max-w-[600px] min-h-[600px] sm:min-h-min  lg:max-w-max lg:w-[850px] rounded-[33px] lg:rounded-50 self-center  `}>
                    <Content
                        headline={{
                            content: HEADLINE,
                            class: `text-[40px] text-center lg:text-left lg:text-6xl text-gini-orange font-Pauline`
                        }}
                        title={{
                            content: TITLE,
                            class: `text-[26px] text-center lg:text-left text-gini-blue lg:text-[32px] font-Poppins font-black leading-[35px] `
                        }}
                        paragraph={{
                            content: PARAGRAPH,
                            class: ` text-base text-[#535455] font-Poppins leading-[25px] max-w-[514px] lg:pt-6`
                        }}
                        button={{
                            content: LETS_TALK,
                            class: `hidden button lg:block mt-6`
                        }}
                        container={` space-y-5 lg:space-y-0 p-5 lg:px-10 lg:py-9 lg:w-3/4 lg:mb-0 `}
                        footerRef={footerRef}
                    />
                </div>
                <img src={curvedArrowRight}
                     className={`hidden absolute lg:block  lg:left-64 lg:bottom-10 xl:bottom-20 `}
                     alt={""}
                />
                <div className={`relative `}>
                    <GenericLottie lottieAsset={studio}/>
                    <img src={curvedArrowLeft}
                         className={`hidden absolute lg:block  lg:right-0 lg:top-16 xl:right-5 xl:top-24`}
                         alt={""}
                    />
                </div>
            </div>
        </div>
    )
}

