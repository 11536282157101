import quotation from '../assets/misc/quotation.svg';

export default function Testimonial({logo, paragraph, name, title}) {
    return (

        <div className={`relative flex justify-center my-10 `}>
            <div
                className={`relative shadow-[0px_60px_10px_-15px_rgba(2,8,161,1)] lg:shadow-[-50px_60px_10px_-15px_rgba(2,8,161,1)]  mb-10 rounded-[32px] py-20 lg:p-5  w-11/12 h-[630px]   lg:w-[750px] lg:h-[385px]  flex flex-col justify-between bg-gini-light-purple  lg:rounded-50 `}>
                <div className={`absolute top-0 left-0 p-4`}>
                    <img src={quotation} className={`h-16 w-16 `} alt={""}/>
                </div>
                <div className={`absolute bottom-0 right-0 p-4`}>
                    <img src={quotation} className={`h-16 w-16 rotate-180`} alt={""}/>
                </div>
                <div className={`mx-auto`}>
                    <img src={logo} alt={"logo"}/>
                </div>
                <p className={`text-white text-center font-Poppins font-medium leading-[25px] px-10`}>
                    {paragraph}
                </p>
                <div>
                    <p className={`text-white text-center font-Poppins font-bold text-[20px]`}>
                        {name}
                    </p>
                    <p className={`text-white text-center font-Poppins leading-[33px] text-[20px]`}>
                        {title}
                    </p>
                </div>
            </div>
        </div>
    )
}
