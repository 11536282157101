export default function validate(values) {
    let errors = {};

    if (!values.name) {
        errors.name = 'Name is required';
    } else if (!/[a-zA-Z]*/.test(values.name)) {
        errors.name = 'Name is invalid';
    }

    if (!values.company) {
        errors.company = 'Company is required';
    } else if (!/[a-zA-Z\d]*/.test(values.company)) {
        errors.company = 'Company is invalid';
    }

    if (!values.email) {
        errors.email = 'Email address is required';
    } else if (!/\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})*/.test(values.email)) {
        errors.email = 'Email address is invalid';
    }

    if (!values.mobile) {
        errors.mobile = 'Mobile number is required';
    } else if (!/\d{10}/.test(values.mobile)) {
        errors.mobile = 'Mobile number is invalid';
    }

    if (!values.option) {
        errors.option = 'Please select service...'
    }

    if (!values.text) {
        errors.text = 'Please tell us something...'
    }

    return errors;
};