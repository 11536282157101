import LandingPage from "./LandingPage";
import './index.css';
import {Route, Routes} from "react-router-dom";
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-32354853-1";
ReactGA.initialize(TRACKING_ID);

export default function App() {
    return (
        <Routes>
            <Route path='/' element={<LandingPage />} />
        </Routes>
    )
}