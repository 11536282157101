import one from "../../assets/clients/one.svg";
import arad from "../../assets/clients/arad.svg";
import playbuzz from "../../assets/clients/playbuzz.svg";
import lotter from "../../assets/clients/lotter.svg";
import cloudinary from "../../assets/clients/cloudinary.svg";
import playstudios from "../../assets/clients/playstudios.svg";

export const HEADLINE = "Our Clients";
export const TITLE = "Say it Best";
export const testimonials = [
    {
        logo: one,
        paragraph: `“The Gini-Apps team is professional and reliable, encouraging innovation and forward thinking, all with a personal approach and high quality service.”`,
        name: `Ori Reich`,
        title: `Director of Mobile`
    },
    {
        logo: arad,
        paragraph: `“Gini-Apps team has proven to be agile, professional and super committed. It truly felt like they were part of our team and i'm looking forward for more projects.”`,
        name: `Tal Zur`,
        title: `VP Software & IT`
    },
    {
        logo: playbuzz,
        paragraph: `“I’ve worked with Gini-Apps on several projects. We found  their mobile experience extremely helpful to us. They were fully committed to the project and delivered swiftly.”`,
        name: `Tom Pachys`,
        title: `Co Founder & CPO`
    },
    {
        logo: lotter,
        paragraph: `“8 years ago our business had no mobile presence. We tasked Gini-Apps with creating our mobile strategy. Today, mobile accounts for 30% of our revenue stream.”`,
        name: `Marcel Klugman`,
        title: `Founder`
    },
    {
        logo: cloudinary,
        paragraph: `“Gini-Apps works with Cloudinary on extending & enriching our mobile SDKs. They are involved in all dev work on our Android (Java & Kotlin) and iOS (swift) SDKs. Gini-Apps’ developers and management are always super-helpful, professional and eager to build, define or just give advice. The day-to-day work with Gini-Apps is smooth and pleasant with all deliveries meeting timelines and our high-standards.  I really enjoy and recommend working with Gini-Apps.”`,
        name: `Asi Sayag`,
        title: `R&D Manager`
    },
    {
        logo: playstudios,
        paragraph: `“Playstudios works with Gini-Apps to develop our game’s mobile SDKs. Gini-Apps engineers are professional and reliable. Working with Gini-Apps was an excellent experience for us, and after a short time, we knew we could rely on them to deliver a professional solution on time.”`,
        name: `Oded Maimon`,
        title: `VP Technologies & Engineering`
    },
]