import {useRef, useEffect} from "react";
import lottie from 'lottie-web';

export default function GenericLottie({lottieAsset}) {
    const container = useRef(null);
    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            lottie.loadAnimation({
                container: container.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: lottieAsset,
            });
        }
        return () => {
            unmounted = true;
        };
    }, []);
    return (
        <div ref={container}></div>
    );
}