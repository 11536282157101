import tadiranBefore1 from "../../assets/products/Tadiran/before1.png";
import tadiranBefore2 from "../../assets/products/Tadiran/before2.png";
import tadiranBefore3 from "../../assets/products/Tadiran/before3.png";
import tadiranAfter1 from "../../assets/products/Tadiran/after1.png";
import tadiranAfter2 from "../../assets/products/Tadiran/after2.png";
import tadiranAfter3 from "../../assets/products/Tadiran/after3.png";
import tadiranLogo from "../../assets/clients/tadiran.svg";

import umBefore from "../../assets/products/Um/umBefore.png";
import umAfter from "../../assets/products/Um/umAfter.png";
import umLogo from "../../assets/clients/um.svg";

import JetTokenBefore1 from "../../assets/products/JetToken/before1.png";
import JetTokenBefore2 from "../../assets/products/JetToken/before2.png";
import JetTokenBefore3 from "../../assets/products/JetToken/before3.png";
import JetTokenAfter1 from "../../assets/products/JetToken/after1.png";
import JetTokenAfter2 from "../../assets/products/JetToken/after2.png";
import JetTokenAfter3 from "../../assets/products/JetToken/after3.png";

import jetTokenLogo from "../../assets/clients/jetToken.svg";

export const HEADLINE = "Products";
export const TITLE = "We Redesigned";

export const tadiran = {
    name: 'Tadiran',
    before: [tadiranBefore1, tadiranBefore2, tadiranBefore3],
    after: [tadiranAfter1, tadiranAfter2, tadiranAfter3],
    logo: tadiranLogo,
    paragraph: `Improved app UI with edgy and modern look.
                Rearranged and properly functioning internal screens.
                Redesigned app flow for improved user experience.
                New screens for fast actions and video calls.`
};

export const um = {
    name: 'Um',
    before: [umBefore],
    after: [umAfter],
    logo: umLogo,
    paragraph: `Pursway is a platform for managing promotional campaigns with placements in movies, 
                TV shows, or YouTube influencer channels. 
                The platform connects high-profile promotional channels and the products looking to buy exposure on these channels.`
};

export const jetToken = {
    name: 'Jet Token',
    before: [JetTokenBefore1, JetTokenBefore2, JetTokenBefore3],
    after: [JetTokenAfter1, JetTokenAfter2, JetTokenAfter3],
    logo: jetTokenLogo,
    paragraph: `
        Redesigned app UI and flow.
        Simplified booking process accessible on the fly. 
        Increased visibility of the best private flight deals. 
        The shortest possible conversion path (completion of the booking process).
    `
}

