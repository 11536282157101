import {useState} from "react";
import Content from "../../components/Content";
import ProductCarousel from "../../components/ProductCarousel";
import ProductCarouselMobile from '../../components/ProductCarouselMobile';
import {HEADLINE, TITLE, tadiran, jetToken, um} from "./constants";

export default function RedesignedProducts() {

    const products = [tadiran, jetToken, um];
    const [indexer, setIndexer] = useState(0);

    return (
        <div className={`mt-20`}>
            <Content
                headline={{
                    content: HEADLINE,
                    class: `text-gini-green text-center font-Pauline text-70`
                }}
                title={{
                    content: TITLE,
                    class: `text-gini-orange text-center font-Poppins font-bold text-40`
                }}
            />
            <div className={`hidden  lg:block my-28`}>
                <ProductCarousel/>
            </div>
            <div className={` mt-20 mb-20 lg:hidden`}>
                {
                    products.map((product, index) => (
                        index <= indexer && <ProductCarouselMobile key={index} product={product}/>
                    ))
                }
                <div className={`my-14 ${indexer === products.length - 1 && "hidden"}`}>
                    <p onClick={() => setIndexer(indexer + 1)}
                       className={`text-center text-[22px] font-bold text-gini-green underline cursor-pointer`}> Show
                        more
                        Projects </p>
                </div>
            </div>
        </div>
    )
}