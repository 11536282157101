import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tadiran from "../sections/RedesignedProducts/Tadiran";
import Um from "../sections/RedesignedProducts/Um";
import JetToken from "../sections/RedesignedProducts/JetToken";
import right from '../assets/misc/slideArrowRight.svg';
import left from '../assets/misc/slideArrowLeft.svg';


export default function ProductCarousel() {

    const ArrowLeft = (props) => (
        <img src={left}
             {...props}
             className={'slick-arrow slick-prev'} alt={""}/>
    );
    const ArrowRight = (props) => (
        <img
            src={right}
            {...props}
            className={'slick-arrow slick-next'} alt={""}/>
    );
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: <ArrowLeft/>,
        nextArrow: <ArrowRight/>,
        customPaging: function (i) {
            return <div className={`text-transparent`}> {i} </div>;
        },
        dotsClass: "slick-dots slick-thumb",
        className: `w-4/5 mx-auto`
    }

    return (
        <div>
            <Slider  {...settings} >
                <Tadiran/>
                <JetToken/>
                <Um/>
            </Slider>
        </div>
    )
}