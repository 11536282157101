import {useState, useRef, useEffect} from "react";
import {scrollToBottom} from "./components/Scroll";

import Introduction from './sections/Introduction';
import DesignProcess from './sections/DesignProcess';
import RedesignedProducts from './sections/RedesignedProducts';
import OurClients from './sections/OurClients';
import Testimonials from "./sections/Testimonials";
import Footer from "./sections/Footer";
import AboutUs from "./sections/AboutUs";
import logo from './assets/misc/GiniAppsLogo.svg';
import stickyEnvelope from './assets/misc/sticky.svg';
import arrowDown from './assets/misc/arrowDown.svg';
import arrowElement from './assets/elements/arrowElement.svg';
import plus from './assets/elements/plus.svg';
import arrow from './assets/elements/arrowElement.svg';
import curvedArrow from './assets/misc/curvedArrowRight.svg';
import floor from './assets/elements/floor.svg';


export default function LandingPage() {

    const footerRef = useRef(null);

    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            let height = document.getElementById("footer").getBoundingClientRect().top;
            let position = window.innerHeight;
            setVisible(position < height)
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <div className={`relative bg-gini-purple h-auto`}>
            <img src={plus} className={`absolute right-5 top-5 lg:right-32 lg:top-16`} alt={""}/>
            <img src={arrowElement} className={`absolute right-10 top-40 lg:right-16 lg:top-32`} alt={""}/>
            {
                visible &&
                <img onClick={() => scrollToBottom(footerRef)} src={stickyEnvelope}
                     className={`z-50 fixed -bottom-10 -right-5 lg:right-0 lg:bottom-0 transition ease-in-out hover:scale-110  duration-300`}
                     alt="contact-form"/>
            }
            <img src={logo} className={`p-10 mx-auto lg:ml-0`} alt="logo"/>
            <Introduction footerRef={footerRef}/>
            <AboutUs footerRef={footerRef}/>
            <img src={arrowDown} className={`mx-auto`} alt=""/>
            <DesignProcess id={"DesignProcess"} footerRef={footerRef}/>
            <div className={`flex justify-center -rotate-90 mt-52`}>
                <img src={arrow} alt={""}/>
            </div>
            <RedesignedProducts/>
            <dicv className={`hidden lg:block relative`}>
                <img src={curvedArrow} className={`absolute left-10`} alt={""}/>
                <img src={floor} className={`absolute right-10`} alt={""}/>
            </dicv>
            <OurClients footerRef={footerRef}/>
            <div className={`h-[150px] lg:h-[350px] relative`}>
                <img src={plus} className={`absolute right-72 top-20`} alt={""}/>
                <img src={floor} className={`absolute -rotate-90 left-72 bottom-20`} alt={""}/>
            </div>
            <Testimonials/>
            <div ref={footerRef}>
                <Footer/>
            </div>
        </div>
    );
}