import Content from "./Content";
import GenericLottie from "./GenericLotti";

export default function Process({process, footerRef}) {
    return (
        <div className={process.styles.container}>
            <Content
                container={process.styles.content}
                title={process.title}
                paragraph={process.paragraph}
                button={process.button}
                footerRef={footerRef}
            />
            <div className={process.styles.lotti}>
                <GenericLottie lottieAsset={process.lotti}/>
            </div>
        </div>
    )
}