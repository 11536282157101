import supercomp from "../../assets/clients/supercomp.png";
import imperva from "../../assets/clients/imperva.svg";
import cloudinary from "../../assets/clients/cloudinary.svg";
import taboola from "../../assets/clients/taboola.svg";
import pango from "../../assets/clients/pango.svg";
import lili from "../../assets/clients/lili.svg";
import playstudios from "../../assets/clients/playstudios.png";
import arad from "../../assets/clients/arad.png";
import namogoo from "../../assets/clients/namogoo.svg";
import pendo from "../../assets/clients/pendo.svg";
import deepinstinct from "../../assets/clients/deepinstinct.png";
import cellwize from "../../assets/clients/cellwize.svg";

export const logos = [
    supercomp, imperva, cloudinary, taboola, pango, lili,
    playstudios, arad, namogoo, pendo, deepinstinct, cellwize
];
export const LETS_WORK_TOGETHER = "Let's work together";
export const MANY_MORE = "And many more...";
export const HEADLINE = "Clients";
export const TITLE = "Works with Us";