import {useState, useEffect, useRef} from "react";
import up from '../assets/misc/up.svg';

export default function Dropdown({items, setOption}) {

    const [show, setShow] = useState(false);
    const [selected, setSelected] = useState("How can we help you?")
    const ref = useRef(null);
    const [hover, setHover] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setShow && setShow(() => false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [setShow]);

    const dropdown = () => {
        setShow(() => !show);
    }

    const select = (item) => {
        setOption(item)
        setSelected(item)
        dropdown()
    }

    return (
        <div ref={ref} className={`dropdown relative lg:cursor-pointer`}
             onMouseEnter={() => setHover(true)}
             onMouseLeave={() => setHover(false)}
        >
            <div
                className={`${hover ? 'border-transparent' : 'border-gini-blue'} border-[1px]   rounded-3xl flex flex-row justify-between p-3`}
                onClick={dropdown}>
                <div>
                    <span className={`text-gini-blue`}> {selected} </span>
                </div>
                <div
                    className={`self-center ${show ? "rotate-0 ease-in-out duration-300" : "rotate-180 ease-in-out duration-300"}`}>
                    <img src={up} className={`h-[15px] w-[15px]`} alt={""}/>
                </div>
            </div>
            <div
                class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg"
                className={`${show ? "absolute bg-white" : "hidden"}  w-full rounded-3xl shadow-lg`}>
                {
                    items.map((item, index) => {
                        return (
                            <div
                                key={index}
                                onClick={() => select(item)}
                                className={`hover:bg-stone-100 ${selected === item && 'font-bold bg-stone-100'} ${(index === 0) && 'rounded-t-3xl'}  ${index === items.length - 1 && 'rounded-b-3xl'}  p-4 text-gini-blue`}
                            >
                                {item}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}