import {useState} from "react";
import {tadiran} from "./constants";

export default function Tadiran() {

    const [hover, setHover] = useState(false)

    return (
        <div className={`w-fit mx-auto mb-20`}>
            <div>
                <div className={`w-[230px]  bg-[#0099C6] h-[4px] rounded-full`}/>
                <p className={` text-40 text-white font-Poppins font-medium text-left ml-3`}> {hover ? 'Before' : 'After'} </p>
                <div className={`w-[170px]  bg-[#0099C6] h-[4px] rounded-full`}/>
            </div>
            <div className={`flex flex-row space-x-10`}>
                <div className={`relative`}>
                    {/* After */}
                    <div
                        className={`absolute left-0 top-10 z-10  hover:opacity-0 transition-opacity ease-in duration-500`}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                    >
                        <div className={`flex flex-row space-x-5 `}>
                            {
                                tadiran.after.map(picture => {
                                    return (
                                        <div>
                                            <img src={picture} alt={"tadiran"}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    {/* Before */}
                    <div className={` mt-10 `}>
                        <div className={`flex flex-row space-x-5 `}>
                            {
                                tadiran.before.map(picture => {
                                    return (
                                        <div>
                                            <img src={picture} alt={"tadiran"}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>


                <div className={`max-w-[240px] mx-auto  self-center`}>
                    <div>
                        <img src={tadiran.logo} alt={"tadiran logo"}/>
                    </div>
                    <p className={`text-white text-left  font-Poppins font-medium leading-[22px] mt-6`}>
                        {tadiran.paragraph}
                    </p>
                </div>
            </div>


        </div>
    )
}