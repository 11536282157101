export default function Input({name, value, error, onChange}) {
    return (
        <>
            <input
                type={name === 'email' ? 'email' : 'text'}
                name={name}
                value={value}
                onChange={onChange}
                placeholder={name[0].toUpperCase() + name.substring(1)}
                autoComplete={"off"}
                className={`  text-gini-blue  placeholder:text-gini-blue border-gini-blue  border-[1px] rounded-3xl p-3 `}
            />
            <div className={`h-[30px]`}>
                {error && (
                    <p className="text-gini-red pl-3 text-[12px]">{error}</p>
                )}
            </div>
        </>
    )
}