import Content from "../../components/Content";
import {logos, MANY_MORE, LETS_WORK_TOGETHER, HEADLINE, TITLE} from "./constants";

export default function OurClients({footerRef}) {
    return (
        <>
            <div className={`mt-20 lg:mt-80`}>
                <Content
                    headline={{
                        content: HEADLINE,
                        class: `text-gini-green text-center font-Pauline text-70`
                    }}
                    title={{
                        content: TITLE,
                        class: `text-gini-orange text-center font-Poppins font-bold text-40`
                    }}
                />
                <div className={`grid grid-cols-2 lg:grid-cols-6  gap-10 lg:gap-14 w-3/4 mx-auto mt-28 items-center`}>
                    {
                        logos.map(logo => {
                            return (
                                <div className={`mx-auto`}>
                                    <img src={logo} alt={"logo"}/>
                                </div>
                            )
                        })
                    }
                </div>
                <Content
                    title={{
                        content: MANY_MORE,
                        class: `text-[26px] text-white font-Poppins font-bold text-center mt-20`
                    }}
                    button={{
                        content: LETS_WORK_TOGETHER,
                        class: `button mt-20  md:mx-auto`
                    }}
                    footerRef={footerRef}
                />
            </div>
        </>
    )
}