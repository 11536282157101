import GenericLottie from "../../components/GenericLotti";
import Content from "../../components/Content";
import welcome from "../../assets/lotties/welcome.json";
import switchElement from "../../assets/elements/switch.svg";
import {HEADLINE, TITLE, PARAGRAPH, LETS_TALK} from "./constants";

export default function Introduction({footerRef}) {
    return (
        <div className={`relative`}>
            <img src={switchElement} className={`absolute -top-10`} alt={""}/>
            <div className={`mt-24 flex flex-col  mx-auto  lg:pl-20 xl:max-w-[1300px] lg:flex-row `}>
                <Content
                    headline={{
                        content: HEADLINE,
                        class: `text-center text-[56px]   text-gini-green  font-Pauline lg:text-[100px] lg:text-left lg:text-100`
                    }}
                    title={{
                        content: TITLE,
                        class: `text-center  text-[26px]  lg:text-[56px] lg:text-left text-gini-orange -mt-5  lg:ml-16  font-Poppins font-semibold leading-[50px]`
                    }}
                    paragraph={{
                        content: PARAGRAPH,
                        class: `text-center text-[14px] lg:text-base lg:text-left text-base font-bold text-white mt-5`
                    }}
                    button={{
                        content: LETS_TALK,
                        class: `hidden lg:block button mt-7 mx-auto  lg:ml-0`
                    }}
                    footerRef={footerRef}
                />
                <div className={`mx-auto `}>
                    <GenericLottie lottieAsset={welcome}/>
                </div>
            </div>
        </div>
    )
}