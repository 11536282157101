import Form from "../../components/Form";
import Content from "../../components/Content";
import GenericLottie from "../../components/GenericLotti";
import logo from '../../assets/misc/GiniAppsLogoBottom.svg';
import contact from '../../assets/lotties/contact.json';
import {HEADLINE, TITLE} from "./constants";

export default function Footer() {
    return (
        <div id={'footer'}
             className={'relative bg-white rounded-t-[30px] lg:rounded-t-100  mt-28   flex justify-center flex-col-reverse lg:flex-row '}>

            <div className={`lg:w-1/2 space-y-0 flex flex-col mt-5 lg:mt-40 `}>
                <div className={`w-1/2 lg:mx-auto`}>
                    <Content
                        headline={{
                            content: HEADLINE,
                            class: `text-center text-[56px] md:text-[90px] lg:text-left text-gini-blue  font-Pauline`
                        }}
                        title={{
                            content: TITLE,
                            class: `text-right text-[26px]  md:text-[45px] text-gini-orange -mt-5  xl:text-left  xl:ml-16  font-Poppins font-semibold leading-[40px]`
                        }}
                    />
                </div>
                <div className={`w-4/5 mx-auto`}>
                    <GenericLottie lottieAsset={contact}/>
                </div>
            </div>
            <div className={`lg:w-1/2`}>
                <Form/>
            </div>
            <div className={` mx-auto mt-10 lg:mt-0 lg:absolute lg:left-10 lg:top-10`}>
                <img src={logo} alt={"logo"}/>
            </div>
        </div>
    )
}