import Content from "./Content";
import GenericLottie from "./GenericLotti";
import confettiLottie from '../assets/lotties/confeti.json';
import confetti from '../assets/elements/confetti.png';

export default function ThankYou() {
    return (
        <>
            <div className={`mt-20 lg:mt-0 flex justify-center`}>
                <img src={confetti} alt={"confetti"}/>
            </div>
            <Content
                headline={{
                    content: 'Thank You.',
                    class: `text-center text-[90px] text-gini-orange  font-Delius`
                }}
                title={{
                    content: 'We’ll be in touch Shortly!',
                    class: `text-center  text-[20px] text-gini-blue  font-Poppins font-semibold leading-[40px]`
                }}
            />
            <div className={`hidden lg:block absolute right-0 bottom-0 max-w-[200px] lg:max-w-[300px]`}>
                <GenericLottie lottieAsset={confettiLottie}/>
            </div>
        </>
    )
}